<template>
  <div class="form">
    <div class="content">
      <Backlink />
      <div class="content__container content__container_profile">
        <div v-if="services.length > 0" >
          <item-service v-for="(service, i) of services" :key="i" :service="service" />
        </div>
        <div v-else-if="!error" class="info">
          {{ 'no_service' | localize }}
        </div>
        <div class="error__container">
          <p class="error" v-if="error">{{ error | localize }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink'
import ItemService from '@/components/profile/ItemService'

export default {
  name: 'Services',
  components: { Backlink, ItemService },
  data: () => ({
    services: [],
    error: null
  }),
  mounted () {
    const userId = this.$store.state.user.id || 0
    this.$store.commit('loading', true)
    this.axios
      .get('users/' + userId + '/services')
      .then((response) => {
        this.services = response.data
        this.$store.commit('loading', false)
      })
      .catch((e) => {
        this.error = 'server_error'
        this.$store.commit('loading', false)
        this.$scrollTo('.error__container', 300, { offset: -400 })
      })
  }
}
</script>
